body {
  margin: 0;
  padding: 0;
}

.clickable {
  cursor: pointer;
}


/* React Spinner */
.react-spinner {
  position: relative;
  width: 32px;
  height: 32px;
  top: 50%;
  left: 50%;
}

.react-spinner_bar {
  -webkit-animation: react-spinner_spin 1.2s linear infinite;
  -moz-animation: react-spinner_spin 1.2s linear infinite;
  animation: react-spinner_spin 1.2s linear infinite;
  border-radius: 5px;
  background-color: white;
  position: absolute;
  width: 20%;
  height: 7.8%;
  top: -3.9%;
  left: -10%;
}

@keyframes react-spinner_spin {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0.15;
  }
}

@-moz-keyframes react-spinner_spin {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0.15;
  }
}

@-webkit-keyframes react-spinner_spin {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0.15;
  }
}