body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #222432;
  font-family: "Montserrat", sans-serif;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.App-header {
  height: 90px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.App-header h1 {
  font-size: 16px;
  font-weight: 250;
}

.App-logo {
  height: 60%;
  width: auto;
}

.App-main {
  position: relative;
  width: 75vw;
  height: 80vh;
  margin: 10px auto;
}

.App-control-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  gap: 1rem;
}

.App-control-container.hidden {
  display: none;
}

.App-control-container .ots-video-control {
  width: 50px;
  height: 50px;
  margin: 20px 0 !important;
  border-radius: 50%;
  background-position: center;
  background-color: lightgrey;
  background-repeat: no-repeat;
  cursor: pointer;
}

.App-control-container .ots-video-control.audio {
  background-image: url(./assets/images/mic.png);
  background-color: #3C4043;
  border: 2px;
}

.App-control-container .ots-video-control.audio.muted:hover,
.App-control-container .ots-video-control.audio.muted {
  background-image: url(./assets/images/mic_off.png);
  background-color: #EA4336;
}

.App-control-container .ots-video-control.video {
  background-image: url(./assets/images/videocam.png);
  background-color: #3C4043;
}

.App-control-container .ots-video-control.video.muted:hover,
.App-control-container .ots-video-control.video.muted {
  background-image: url(./assets/images/videocam_off.png);
  background-color: #EA4336;
}

.App-control-container .ots-video-control.end-call {
  background-image: url(./assets/images/call_end.png);
  background-color: #EA4336;
}

.ots-video-control.share-screen:hover,
.ots-video-control.share-screen {
  background-image: url(./assets/images/screen_share.png);
  background-color: #3C4043;
}

.App-video-container {
  position: relative;
  width: 80%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App-mask {
  width: 80%;
  height: 60vh;
  background-color: #222;
}

.App-btn {
  padding: 1rem;
  display: grid;
  place-items: center;
  background-color: #EFF2F7;
  width: 100%;
  height: 100%;
  align-content: center
}

.App-message {
  height: 100%;
}

.App-btn-container {
  height: 100%;
}

.btn-ingress {
  width: 10rem;
  height: 2.5rem;
  border: none;
  border-radius: 1.5rem;
  background-color: rgb(136, 129, 188);
  color: azure;
}

.App-tusicologo {
  height: 100%;
  width: 100%;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.App-logo-tusicologo {
  height: 10%;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-content: center;
}

.App-mask .react-spinner {
  position: absolute;
}

.App-mask .message {
  font-weight: 200;
}

.App-mask .message.with-spinner {
  position: absolute;
  top: 55%;
}

.App-mask .message.button {
  border: 1px solid white;
  padding: 20px 40px;
  border-radius: 6px;
  background-color: Transparent;
  color: white;
  font-size: medium;
}

.App-mask .message.button:focus:active,
.message.button:focus,
.message.button:active {
  background-image: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.App-video-container .video-container {
  width: 100%;
  height: 100%;
  display: flex;
}

.App-video-container .video-container.small {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 226px;
  height: 145px;
  z-index: 2;
  border-radius: 1.5rem !important;
}

@media screen and (max-width: 760px) {
  .App-main {
    position: relative;
    width: 90vw;
    height: 85vh;
    margin: 10px auto;
  }

  .App-btn {
    padding: 1rem;
    display: grid;
    place-items: center;
    background-color: #EFF2F7;
    width: 100%;
    height: 100%;
    align-content: center;
    border-radius: 2rem;
  }

  .App-control-container {
    width: 90vw;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    gap: 1rem;
  }

  .App-mask {
    display: inline;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    height: 60vh;
    /* background-color: #222; */
  }

  .App-tusicologo {
    width: 0px;
    height: 0px;
    display: none;
  }

  .App-video-container .video-container {
    width: 100%;
    height: 100%;
    display: grid;
  }

  .App-video-container .video-container.small {
    position: absolute;
    bottom: -10px;
    right: 0px;
    width: 160px;
    height: 96px;
    z-index: 2;
    border-radius: 1.5rem !important;
    margin-bottom: 20px;
    top: inherit;
  }

  .App-video-container .video-container.active-gt2 {
    display: grid !important;
    flex-direction: row;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
  }

  .App-video-container .video-container.small.left {
    display: none;
  }

  .App-video-container .video-container.small.right {
    display: none;
  }
}

.App-video-container .video-container.small.left {
  left: 20px;
}

.App-video-container .video-container.hidden {
  display: none;
}

.App-video-container .video-container.active-gt2 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
}

.App-video-container .video-container.active-gt2.hidden {
  display: none;
}

.App-video-container .video-container.small.left.active-gt2.hidden {
  display: none;
}

.App-video-container .video-container.active-gt2 .OT_subscriber {
  margin: 0;
  padding: 0;
  display: inline-block;
  font-size: 0;
  flex-basis: 50%;
  box-sizing: border-box;
  width: auto !important;
  height: auto !important;
  border-radius: 2rem !important;
}

.App-video-container .video-container.active-gt2.active-odd .OT_subscriber:first-child {
  flex-basis: 100%;
}

.OT_publisher .OT_video-element,
.OT_subscriber .OT_video-element {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  transform-origin: 0 0;
  border-radius: 1rem;
}

.OT_widget-container {
  position: absolute;
  /* background: linear-gradient(60.08deg, #333333 0%, #1A1A1A 100%); */
  overflow: hidden;
  border-radius: 1rem;
}